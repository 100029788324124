<!--
 * @Author: ZHONGHUIDI
 * @Date: 2022-07-18 10:24:37
 * @LastEditors: ZHONGHUIDI
 * @LastEditTime: 2022-07-19 10:30:45
 * @FilePath: \QAPreject\src\views\introduce.vue
-->
<template>
    <div class="w-full mt-36 " >  
        <div  >
         <swiper   :slidesPerView ="3" :autoplay="{ delay: 2000, disableOnInteraction: false }" :loop="true" :space-between="20" :modules="modules" >
                <swiper-slide class="swiper-slide" v-for="(item, i) in aImages" :key="i">
                    <img :src="item.picUrl" alt=""  />
                </swiper-slide>
        </swiper>
        </div>
        <div>
            <div data-v-0a9e43fc="" class="det-intro-container">
                <div data-v-0a9e43fc="" class="mt-8 text-lg font-black text-black" >应用信息  </div>
                <div data-v-0a9e43fc="" id="J_DetAppDataInfo" class="det-intro-text-new" style="">
                    <div data-v-0a9e43fc="" class="det-app-data-info ">
                        小雷智家是针对全屋智能家居打造的一款智能设备控制和管理的工具，可以轻松实现智能设备设置、控制和监控，智能场景的设置和控制，家庭空间和成员管理等功能，为全屋智能提供简单易用的解决方案。<br>1、远程控制，随时随地监控智能设备的运行状态，并实时控制；<br>2、一键执行，一键执行智能化场景，实现多设备的联动；<br>3、语音控制，通过云云接入的方式接入市面上主流的智能音箱，实现语音控制；<br>4、成员共享，通过家庭成员共享功能，家庭成员都可以控制家里的设备；<br>5、快速连网，支持自动发现设备、多设备同时入网。
                    </div>
                </div>
            </div>
        </div>
        <div  class="w-40 "  style=" position: fixed; right:20px; bottom:20px;  " >
         <img src="../assets/xlzj.png"   style="background:red" />
         <button @click="download()"   class="px-12 py-2 mt-3 font-semibold text-white bg-blue-500 rounded-lg shadow-md md:bg-green-500 bg-emerald-500 hover:bg-green-700 focus:outline-none">立刻下载</button>  
        </div>
</div>
</template>
<script>
import { onMounted, ref} from 'vue';
    // import { Swiper, SwiperSlide } from 'swiper/vue';
  import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
  import { Autoplay } from 'swiper'
  import "swiper/swiper-bundle.css";
  export default {
    components: {
      Swiper,
      SwiperSlide,
    },
    setup() {  
        const aImages = ref([
            {picUrl:"https://pp.myapp.com/ma_pic2/0/shot_54244841_1_1657760095/550"},
            {picUrl:"https://pp.myapp.com/ma_pic2/0/shot_54244841_2_1657760095/550"},
            {picUrl:"https://pp.myapp.com/ma_pic2/0/shot_54244841_3_1657760095/550"},
            {picUrl:"https://pp.myapp.com/ma_pic2/0/shot_54244841_4_1657760095/550"},
            {picUrl:"https://pp.myapp.com/ma_pic2/0/shot_54244841_5_1657760095/550"}, 
            {picUrl:"https://pp.myapp.com/ma_pic2/0/shot_54244841_1_1657760095/550"},
            {picUrl:"https://pp.myapp.com/ma_pic2/0/shot_54244841_2_1657760095/550"},
            {picUrl:"https://pp.myapp.com/ma_pic2/0/shot_54244841_3_1657760095/550"},
            {picUrl:"https://pp.myapp.com/ma_pic2/0/shot_54244841_4_1657760095/550"},
            {picUrl:"https://pp.myapp.com/ma_pic2/0/shot_54244841_5_1657760095/550"}, 
        ]);
        const download=()=>{
            window.location.href = 'https://app.nvc-smart.com/d/leihome'
        } ;
        const onSwiper = (swiper) => {
          console.log(swiper);
        };
        const onSlideChange = () => {
          console.log('slide change');
        };
        return {
          onSwiper,
          onSlideChange, download,
          aImages,
           modules: [Autoplay]
        };
    },
  };
</script>
<style scoped>
 .swiper-slide{
    width:300px;/*设为固定值*/
    width:auto;/*根据内容调整宽度*/
  }

</style>